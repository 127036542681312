.product-variant {
    font-size: 12px;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 33%);

    &__wrapper{
        width: 185px;
        padding: 0.5rem;

        @media (max-width: 1280px) {
            width: 25%;
        }
    }

    &--picture {
        display: block;
        height: 130px;
        width: 100%;
        margin: 0 auto;
        object-fit: contain;

        @media (max-width: 1280px) {
            height: 100px;
        }
    }

    &--quantity {
        margin: 0 auto 0;
        width: 90px;
        
        input{
            border: none;
        }

        button{
            background: transparent;
        }
    }

    &--name{
        font-size: .85rem;
        line-height: .7rem;
        text-align: center;
        font-weight: 300;
        margin-bottom: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--active {
        outline: 2px solid #121a42;
    }
}
