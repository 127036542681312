.modal{
    &.show{
        display: block;
    }
     overflow: hidden !important;

    .modal-dialog{
        display: flex;
        height: 100%;
        align-items: center;
        margin-top: 0;
    }

    .modal-content{
        position: relative;
        border-radius: 4px;
        border: none;
        padding: 1.75rem;

        .closeBtn{
            position: absolute;
            top: 0.5rem;
            right: 0.6rem;
            border: none !important;
            padding: 0;
            background: none !important;
        }
    }

    background: rgba(0,0,0,0.4);
}