@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  overflow-x: hidden;
}


.btn.btn-primary{
  border-radius: 0;
  background-color: #353e6d;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  border: none;

  &:hover,&:active,&:focus,&:focus-visible,&:focus-within{
      background-color: #121a42 !important;
  }

  &:disabled{
    background-color: #283370 !important;
  }
}