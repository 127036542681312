.product-group {
    margin: 20px 0;


    & > h2{
        font-weight: 100;
        margin-bottom: 1rem;
    }

    &__products{
        display: flex;
        flex-wrap: wrap;
        flex-basis: 45%;
    }

    &__variant{
         
        & > p {
            font-size: 1.15rem;
            font-weight: 100;
            margin: 2rem 0;
        }
    }
}
