.app {

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    background: #fff;

    .app-header {
        img {
            background: #fff;
        }
    }

    .app-content {
        position: relative;

        &--lock {
            &::before {
                content: '';
                position: absolute;
                inset: 0;
                z-index: 3;
                background: #fff;
                opacity: 0.5;
            }
        }
    }

    .app-logo {
        height: 80px;
        margin: 15px 0 0 15px;
    }

    h2,
    h1 {
        font-weight: 300;
    }
}